var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"FM_page-changePassword"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 offset-lg-4 col-xl-4 offset-xl-4"},[_c('h1',{staticClass:"FM_logo-login text-center"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"img-logo",attrs:{"src":require("@/assets/logo/logo-h.png"),"width":"100%"}})])],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"FM_loginBox",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('h2',{staticClass:"text-title"},[_vm._v(_vm._s(_vm.$t('__resetPassword')))]),_c('p',{staticClass:"text-subTitle"},[_vm._v(_vm._s(_vm.$t('__contain8Characters')))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formStatus.success === 0),expression:"formStatus.success === 0"}],staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.formStatus.errorMsg)+" ")]),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"vid":"password","rules":"required|min:8","name":_vm.$t('__oldPassword')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.oldPassword),expression:"form.oldPassword"}],staticClass:"form-control FM_inputStyle-circle",class:{ 'invalid': errors.length > 0 },attrs:{"type":"password","disabled":_vm.formStatus.spinner,"placeholder":_vm.$t('__oldPassword')},domProps:{"value":(_vm.form.oldPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "oldPassword", $event.target.value)}}}),_c('span',{staticClass:"text-danger FM_input-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"vid":"newPassword","rules":"required|min:8","name":_vm.$t('__newPassword')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control FM_inputStyle-circle",class:{ 'invalid': errors.length > 0 },attrs:{"type":"password","disabled":_vm.formStatus.spinner,"placeholder":_vm.$t('__newPassword')},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('span',{staticClass:"text-danger FM_input-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required|confirmed:newPassword","name":_vm.$t('__confirmNewPassword')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.confirm),expression:"form.confirm"}],staticClass:"form-control FM_inputStyle-circle",class:{ 'invalid': errors.length > 0 },attrs:{"type":"password","disabled":_vm.formStatus.spinner,"placeholder":_vm.$t('__confirmNewPassword')},domProps:{"value":(_vm.form.confirm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "confirm", $event.target.value)}}}),_c('span',{staticClass:"text-danger FM_input-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('button',{staticClass:"btn btn-success btn-block FM_btn FM_submit-account",attrs:{"type":"submit","disabled":_vm.formStatus.spinner}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.formStatus.spinner),expression:"formStatus.spinner"}],staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t('__submit'))+" ")]),_c('button',{staticClass:"btn btn-success btn-block FM_btn FM_submit-account",staticStyle:{"margin":"15px 0 0"},attrs:{"type":"button","disabled":_vm.formStatus.spinner},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t('__back'))+" ")])])]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }