<template>
	<div class="FM_page-changePassword">
		<div class="container">
			<div class="row">
				<div class="col-lg-4 offset-lg-4 col-xl-4 offset-xl-4">
					<h1 class="FM_logo-login text-center">
						<router-link to="/">
							<img src="~@/assets/logo/logo-h.png" class="img-logo" width="100%">
						</router-link>
					</h1>
					<ValidationObserver v-slot="{ handleSubmit }">
						<form class="FM_loginBox" autocomplete="off" @submit.prevent="handleSubmit(submit)">
							<h2 class="text-title">{{ $t('__resetPassword') }}</h2>
							<p class="text-subTitle">{{ $t('__contain8Characters') }}</p>
							<div class="alert alert-danger" role="alert" v-show="formStatus.success === 0">
								{{ formStatus.errorMsg }}
							</div>
							<div class="form-group">
								<ValidationProvider vid="password" rules="required|min:8" :name="$t('__oldPassword')" v-slot="{ errors }">
									<input type="password" v-model="form.oldPassword" class="form-control FM_inputStyle-circle" :class="{ 'invalid': errors.length > 0 }" :disabled="formStatus.spinner" :placeholder="$t('__oldPassword')">
									<span class="text-danger FM_input-error">{{ errors[0] }}</span>
								</ValidationProvider>
							</div>
							<div class="form-group">
								<ValidationProvider vid="newPassword" rules="required|min:8" :name="$t('__newPassword')" v-slot="{ errors }">
									<input type="password" v-model="form.password" class="form-control FM_inputStyle-circle" :class="{ 'invalid': errors.length > 0 }" :disabled="formStatus.spinner" :placeholder="$t('__newPassword')">
									<span class="text-danger FM_input-error">{{ errors[0] }}</span>
								</ValidationProvider>
							</div>
							<div class="form-group">
								<ValidationProvider rules="required|confirmed:newPassword" :name="$t('__confirmNewPassword')" v-slot="{ errors }">
									<input type="password" v-model="form.confirm" class="form-control FM_inputStyle-circle" :class="{ 'invalid': errors.length > 0 }" :disabled="formStatus.spinner" :placeholder="$t('__confirmNewPassword')">
									<span class="text-danger FM_input-error">{{ errors[0] }}</span>
								</ValidationProvider>
							</div>
							<button type="submit" class="btn btn-success btn-block FM_btn FM_submit-account" :disabled="formStatus.spinner">
								<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="formStatus.spinner"></span>
								{{ $t('__submit') }}
							</button>
							<button type="button" class="btn btn-success btn-block FM_btn FM_submit-account" :disabled="formStatus.spinner" @click="$router.go(-1)" style="margin: 15px 0 0;">
								{{ $t('__back') }}
							</button>
						</form>
					</ValidationObserver>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Ajax } from "@/api/index.js"
import { API } from "@/api/api.js"
import { app } from "@/main.js"
import { urlReplace } from "@/utils/index.js"
import { getStorage } from "@/utils/storage.js"
import { extend } from 'vee-validate'
import { required, min, confirmed } from 'vee-validate/dist/rules'
import CN from 'vee-validate/dist/locale/zh_CN.json'
import { localize } from 'vee-validate'

export default {
	name: "ChangePassword",
	data(){
		return{
			form: {
				oldPassword: '',
				password: '',
				confirm: ''
			},
			formStatus: {
				spinner: false,
				success: null, // 0:fail / 1:success / null: not logined
				errorMsg: ''
			}
		}
	},
	created() {
		if (getStorage('lang') === 'cn') {
			localize('zh_CN', CN);
		}
		extend('required', required);
		extend('min', min);
		extend('confirmed', {
			...confirmed,
			message: '{_field_}' + app.$t('__error')
		});
	},
	methods: {
		resetStatus() {
			this.formStatus = {
				spinner: false,
				success: null, // 0:fail / 1:success / null: not logined
				errorMsg: ''
			}
		},
		submit() {
			this.resetStatus();
			this.$Progress.start();
			this.formStatus.spinner = true;

			let _data = {
				password_ori: this.form.oldPassword,
				password: this.form.password
			};

			Ajax(
				API.changePassword.method,
				API.changePassword.url,
				_data
			).then(async (result) => {
				this.formStatus.spinner = false;
				this.$Progress.finish();

				if(result.success !== 1) {
					this.formStatus.success = result.success;
					this.formStatus.errorMsg = result.error_msg;
					return ;
				}

				alert('Reset Password Success!');
				await this.$router.push('/');
			})
		}
	}
}
</script>

<style scoped>

</style>